import "@simplur/gatsby-theme-gutenberg-custom-blocks/lib/prod.css";

import HeroSection from "./gutenberg-components/HeroSection";
import ProductCarousel from "./gutenberg-components/ProductCarousel";
import BrandCarousel from "./gutenberg-components/ShopByBrand";
import CategoryCarousel from "./gutenberg-components/ShopByCategory";
import PromoSection from "./gutenberg-components/PromoSection";
import CoreGroupBlock from "./gutenberg-components/CoreGroupBlock";

export const GetCustomBlock = name => {
  switch (name) {
    case "moores-features/hero-section":
      return HeroSection;
    case "moores-features/product-carousel":
      return ProductCarousel;
    case "moores-features/shop-by-brand":
      return BrandCarousel;
    case "moores-features/shop-by-category":
      return CategoryCarousel;
    case "moores-features/promo-section":
      return PromoSection;
    case "core/group":
      return CoreGroupBlock;
    default:
      return null;
  }
};

export const parseJsonBlocksData = arr => {
  if (!arr) {
    return;
  }
  return arr.map(obj => {
    obj["blockName"] = obj["name"];
    obj["attrs"] = obj["attributes"];
    obj["innerHTML"] = obj["saveContent"];
    obj["innerHTML"] = obj["saveContent"];
    obj["attrs"]["innerBlocks"] = obj["innerBlocks"];
    delete obj["name"];
    delete obj["attributes"];
    delete obj["saveContent"];
    if (obj.innerBlocks && 0 < obj.innerBlocks.length) {
      parseJsonBlocksData(obj.innerBlocks);
    }
    return obj;
  });
};

/**
 * Check if is browser.
 *
 * @return {boolean}
 */
const isBrowser = () => {
  return "undefined" !== typeof window;
};

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @todo I doubt DOMPurify will work during SSR, test and update accordingly.
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
// export const sanitize = content => {
// 	const decodedContent = entities.decode( content );
// 	return isBrowser() ? DOMPurify.sanitize( decodedContent ) : decodedContent;
// };
