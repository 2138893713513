import React, { useState } from "react";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import "./style.scss";

const HeroCarousel = ({ slideImages }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === slideImages?.carouselImages.length - 1
        ? 0
        : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0
        ? slideImages?.carouselImages.length - 1
        : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const carouselImages = slideImages?.carouselImages || [];

  const slidesId = carouselImages.map((item) => {
    return item.carouselImage.id;
  });

  const slides = carouselImages.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.carouselImage.id}
      >
      <a href={item.imageLink ? item.imageLink : '' }>
        <img
          className="hero__slide-image"
          src={item.carouselImage.sourceUrl}
          srcSet={item.carouselImage.srcSet}
          alt={item.carouselImage.altText}
          loading="eager"
        />
        </a>
      </CarouselItem>
    );
  });

  return (
    <div className="mt-3 hero__carousel">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        autoPlay="false"
      >
        <CarouselIndicators
          items={slidesId}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};

export default HeroCarousel;
