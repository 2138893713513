import React from "react";
import { Link } from "gatsby";
const PromoButton = ({ label, link, color, backgroundColor }) => {
  return (
    <div className="button__wrap">
      <Link className="btn" to={`/${link}`} style={{ color, backgroundColor }}>
        {label}
      </Link>
    </div>
  );
};

export default PromoButton;
