import React from "react";
import { CardImg } from "reactstrap";
import { Link } from "gatsby";

const CategoryThumbnail = ({ src, alt, slug }) => {
  let imgUrl = src;
  if (imgUrl) {
    imgUrl = imgUrl.replace(/(\.[\w\d_-]+)$/i, "-300x300$1");
  }
  return (
    <Link
      className="category__thumb partial-border"
      to={`/product-category/${slug}`}
    >
      <img
        loading="lazy"
        className="card-img-top"
        width="100%"
        src={imgUrl}
        alt={alt}
      />
    </Link>
  );
};

export default CategoryThumbnail;
