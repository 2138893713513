import React from "react";
import { CardImg } from "reactstrap";
import { Link } from "gatsby";

const BrandThumbnail = ({ src, alt, slug }) => {
  let imgUrl = src;
  if (imgUrl) {
    imgUrl = imgUrl.replace(/(\.[\w\d_-]+)$/i, "-300x300$1");
  }
  return (
    <Link className="brand__thumb" to={`/product-category/brands/${slug}`}>
      <img
        loading="eager"
        className="card-img-top"
        width="100%"
        src={imgUrl}
        alt={alt}
      />
    </Link>
  );
};

export default BrandThumbnail;
