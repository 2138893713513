import React from "react";

import CardModal from '../../../gatsby-theme-full-site/components/CardModal';

const RequestInformation = ({ type, label, product }) => {
  return (
	  <CardModal product={product} type={type} />
  );
};

export default RequestInformation;
