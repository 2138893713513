import React from "react";
import { CardTitle } from "reactstrap";
import { Link } from "gatsby";
import { decode } from "html-entities";

const ProductTitle = ({ name, slug }) => {
  return (
    <CardTitle className="product__title mb-2">
      <Link to={`/shop/${slug}`} className="line-clamp">
        {decode(name)}
      </Link>
    </CardTitle>
  );
};

export default ProductTitle;
