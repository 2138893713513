import React from "react";
import { Link } from "gatsby";

const BuyNowButton = ({ label, slug }) => {
  return (
    <Link className="btn btn-cta btn-block" to={`/shop/${slug}`}>
      {label}
    </Link>
  );
};

export default BuyNowButton;
