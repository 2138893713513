import React from "react";
import { HeroSection } from "@simplur/gatsby-theme-gutenberg-custom-blocks";

const HeroSectionComponent = ({ attrs }) => {
  return (
    <HeroSection
      heroProducts={attrs.heroProducts}
      newProducts={attrs.newProducts}
      title={attrs.title}
      attributes={attrs}
    />
  );
};

export default HeroSectionComponent;
