import React from "react";
import { Container } from "reactstrap";
import WPGBlocks from "react-gutenberg";

import { useWishList } from "@simplur/gatsby-theme-full-site/src/hooks";
import { GetCustomBlock, parseJsonBlocksData } from "../../../../functions";

import HeroCarousel from "../../components/HeroCarousel/index";
import Layout from "../../components/layout";
import SEO from "@simplur/gatsby-theme-full-site/src/components/seo";

const IndexPage = ({ pageContext }) => {
  const { products, data } = pageContext;
  useWishList(products);

  const title = data?.wpgraphql?.page?.seo?.title;
  const description = data?.wpgraphql?.page?.seo?.metaDesc;

  let { blocksJSON, ACFHeroCarousel = {} } = data.wpgraphql.page;

  blocksJSON = JSON.parse(blocksJSON);

  return (
    <Layout location="home">
      <SEO title={title} description={description} />
      <div className="wrapper-home">
        <Container>
          <HeroCarousel slideImages={ACFHeroCarousel} />
          <WPGBlocks
            blocks={parseJsonBlocksData(blocksJSON)}
            mapToBlock={GetCustomBlock}
          />
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
