import React from "react";
import { Container } from "reactstrap";
import { PromoSection } from "@simplur/gatsby-theme-gutenberg-custom-blocks";

const PromoSectionComponent = ({ attrs }) => {
  const backgroundColor = attrs?.style?.color?.background;
  return (
    <div className="alignfull" style={{ background: backgroundColor }}>
      <Container>
        <PromoSection {...attrs} />
      </Container>
    </div>
  );
};

export default PromoSectionComponent;
