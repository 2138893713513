import React from "react";
import { CardImg } from "reactstrap";
import { Link } from "gatsby";

const ProductThumbnail = ({ src, alt, slug }) => {
  let imgUrl = src;
  if (imgUrl) {
    imgUrl = imgUrl.replace(/(\.[\w\d_-]+)$/i, "-300x300$1");
  }
  return (
    <Link
      to={`/shop/${slug ? slug : "#"}`}
      className="product__thumb partial-border card-image-wrapper"
    >
      <img
        loading="lazy"
        className="p-2 card-img-top"
        width="100%"
        src={imgUrl}
        alt={alt}
      />
    </Link>
  );
};

export default ProductThumbnail;
