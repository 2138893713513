import React from "react";
import { ProductCarouselSlider } from "@simplur/gatsby-theme-gutenberg-custom-blocks";

const ProductCarouselComponent = ({ attrs }) => {
  return (
    <div className="product__carousel">
      <ProductCarouselSlider products={attrs.products} attributes={attrs} />
    </div>
  );
};

export default ProductCarouselComponent;
