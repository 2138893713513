import React from "react";
import { CardTitle } from "reactstrap";
import { Link } from "gatsby";
import { decode } from "html-entities";

const CategoryTitle = ({ slug, name }) => {
  return (
    <div className="category__name">
      <CardTitle className="text-center">
        <Link to={`/product-category/${slug}`}>{decode(name)}</Link>
      </CardTitle>
    </div>
  );
};

export default CategoryTitle;
